async function importKey ( format, keyData, algorithm, extractable, keyUsages )
{
  return crypto.subtle.importKey(format, keyData, algorithm, extractable, keyUsages);
}

async function rsaDecrypt ( data, key ) 
{
  var keyObject = await importKey('pkcs8', base64ToUint8Array(key), { name: 'RSA-OAEP', hash: { name: 'SHA-256' } }, true, ['decrypt']);
  var encodedData = await crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
      hash: { name: 'SHA-256' }
    },
    keyObject,
    base64ToUint8Array(data)
  );
  return base64ToBinary(Uint8ArrayToBase64(encodedData));
}


function stringToUint8Array ( data )
{
  return Uint8Array.from(data, c => c.charCodeAt(0));
}

function Uint8ArrayToString ( data )
{
  return String.fromCharCode.apply(null, data);
}

function binaryToBase64 ( data )
{
  return btoa(data);
}

function base64ToBinary ( data )
{
  return atob(data);
}

function encodeData ( data )
{
  return new TextEncoder('utf-8').encode(data);
}

function decodeData ( data )
{
  return new TextDecoder('utf-8').decode(data);
}

function base64ToUint8Array ( key )
{
  const binaryKey       = base64ToBinary(key);
  const Uint8ArrayKey   = stringToUint8Array(binaryKey);
  return Uint8ArrayKey;
}

function Uint8ArrayToBase64 ( data )
{
  const stringData = Uint8ArrayToString(new Uint8Array(data));
  const base64Data = binaryToBase64(stringData);
  return base64Data;
}


// Function to decrypt form data
export async function decryptAllFormData(encryptedFormData, privateKey) {
    const decryptedData = [];
    try {
        for (const key in encryptedFormData) {
            const {
                id,
                first_name,
                last_name,
                date_of_birth,
                father,
                mother,
                city,
                street,
                house_number,
                zip_code,
                mobile,
                landline,
                email,
                fallback_contact,
                fallback_number,
                swimming_allowed,
                swimming_capability,
                swimming_badge,
                tools_allowed,
                vaccination_fsme_year,
                vaccination_tetanus,
                vaccination_tetanus_year,
                vaccination_fsme,
                tick_removal_allowed,
                allergies,
                vegetarian,
                food_intolerance,
                medication_allowed,
                medication,
                friend_1,
                friend_2,
                gender,
                siblings_in_same_tent,
                additional_details,
                is_fee_paid,
                fee_amount,
                agreement_signed,
                media_allowed,
                confirmation_email_sent,
                is_archived,
                payment_date
            } = encryptedFormData[key];
    
            // Decrypt each field and push the decrypted object to the decryptedData array
            decryptedData.push({
                id:id,
                first_name: await rsaDecrypt(first_name, privateKey),
                gender: await rsaDecrypt(gender, privateKey),
                last_name: await rsaDecrypt(last_name, privateKey),
                date_of_birth: await rsaDecrypt(date_of_birth, privateKey),
                father: await rsaDecrypt(father, privateKey),
                mother: await rsaDecrypt(mother, privateKey),
                city: await rsaDecrypt(city, privateKey),
                street: await rsaDecrypt(street, privateKey),
                house_number: await rsaDecrypt(house_number, privateKey),
                zip_code: await rsaDecrypt(zip_code, privateKey),
                mobile: await rsaDecrypt(mobile, privateKey),
                landline: await rsaDecrypt(landline, privateKey),
                email: await rsaDecrypt(email, privateKey),
                fallback_contact: await rsaDecrypt(fallback_contact, privateKey),
                fallback_number: await rsaDecrypt(fallback_number, privateKey),
                swimming_allowed: await rsaDecrypt(swimming_allowed, privateKey),
                swimming_capability: await rsaDecrypt(swimming_capability, privateKey),
                swimming_badge: await rsaDecrypt(swimming_badge, privateKey),
                tools_allowed: await rsaDecrypt(tools_allowed, privateKey),
                vaccination_tetanus: await rsaDecrypt(vaccination_tetanus, privateKey),
                vaccination_tetanus_year: await rsaDecrypt(vaccination_tetanus_year, privateKey),
                vaccination_fsme: await rsaDecrypt(vaccination_fsme, privateKey),
                tick_removal_allowed: await rsaDecrypt(tick_removal_allowed, privateKey),
                allergies: await rsaDecrypt(allergies, privateKey),
                vegetarian: await rsaDecrypt(vegetarian, privateKey),
                food_intolerance: await rsaDecrypt(food_intolerance, privateKey),
                medication_allowed: await rsaDecrypt(medication_allowed, privateKey),
                medication: await rsaDecrypt(medication, privateKey),
                friend_1: await rsaDecrypt(friend_1, privateKey),
                friend_2: await rsaDecrypt(friend_2, privateKey),
                siblings_in_same_tent: await rsaDecrypt(siblings_in_same_tent, privateKey),
                vaccination_fsme_year: await rsaDecrypt(vaccination_fsme_year, privateKey),
                additional_details: await rsaDecrypt(additional_details, privateKey),
                is_fee_paid : await rsaDecrypt(is_fee_paid, privateKey),
                fee_amount : await rsaDecrypt(fee_amount, privateKey),
                agreement_signed : await rsaDecrypt(agreement_signed, privateKey),
                media_allowed : await rsaDecrypt(media_allowed, privateKey),
                confirmation_email_sent : await rsaDecrypt(confirmation_email_sent, privateKey),
                is_archived: await rsaDecrypt(is_archived, privateKey),
                payment_date: await rsaDecrypt(payment_date, privateKey),
                // downloaded_consent_form: await rsaDecrypt(downloaded_consent_form, privateKey),
               
            });
        }
    
        return decryptedData;
    }catch(error) {
        return {
            error,
            isDecrypted: false
        }
    }
}

// Function to decrypt form data
export async function decryptSingleFormData(encryptedFormData, privateKey) {
    const decryptedData = {};
    try {
        const {
            id,
            first_name,
            last_name,
            date_of_birth,
            father,
            mother,
            city,
            street,
            house_number,
            zip_code,
            mobile,
            landline,
            email,
            fallback_contact,
            fallback_number,
            swimming_allowed,
            swimming_capability,
            swimming_badge,
            tools_allowed,
            vaccination_fsme_year,
            vaccination_tetanus,
            vaccination_tetanus_year,
            vaccination_fsme,
            tick_removal_allowed,
            allergies,
            vegetarian,
            food_intolerance,
            medication_allowed,
            medication,
            friend_1,
            friend_2,
            gender,
            siblings_in_same_tent,
            additional_details,
            is_archived,
            is_fee_paid,
            fee_amount,
            agreement_signed,
            media_allowed,
            confirmation_email_sent,
            payment_date
        } = encryptedFormData;

        // Decrypt each field and push the decrypted object to the decryptedData array
        decryptedData.id = id
        decryptedData.first_name = await rsaDecrypt(first_name, privateKey)
        decryptedData.gender = await rsaDecrypt(gender, privateKey)
        decryptedData.last_name = await rsaDecrypt(last_name, privateKey)
        decryptedData.date_of_birth = await rsaDecrypt(date_of_birth, privateKey)
        decryptedData.father = await rsaDecrypt(father, privateKey)
        decryptedData.mother = await rsaDecrypt(mother, privateKey)
        decryptedData.city = await rsaDecrypt(city, privateKey)
        decryptedData.street = await rsaDecrypt(street, privateKey)
        decryptedData.house_number = await rsaDecrypt(house_number, privateKey)
        decryptedData.zip_code = await rsaDecrypt(zip_code, privateKey)
        decryptedData.mobile = await rsaDecrypt(mobile, privateKey)
        decryptedData.landline = await rsaDecrypt(landline, privateKey)
        decryptedData.email = await rsaDecrypt(email, privateKey)
        decryptedData.fallback_contact = await rsaDecrypt(fallback_contact, privateKey)
        decryptedData.fallback_number = await rsaDecrypt(fallback_number, privateKey)
        decryptedData.swimming_allowed = await rsaDecrypt(swimming_allowed, privateKey)
        decryptedData.swimming_capability = await rsaDecrypt(swimming_capability, privateKey)
        decryptedData.swimming_badge = await rsaDecrypt(swimming_badge, privateKey)
        decryptedData.tools_allowed = await rsaDecrypt(tools_allowed, privateKey)
        decryptedData.vaccination_tetanus = await rsaDecrypt(vaccination_tetanus, privateKey)
        decryptedData.vaccination_tetanus_year = await rsaDecrypt(vaccination_tetanus_year, privateKey)
        decryptedData.vaccination_fsme = await rsaDecrypt(vaccination_fsme, privateKey)
        decryptedData.tick_removal_allowed = await rsaDecrypt(tick_removal_allowed, privateKey)
        decryptedData.allergies = await rsaDecrypt(allergies, privateKey)
        decryptedData.vegetarian = await rsaDecrypt(vegetarian, privateKey)
        decryptedData.food_intolerance = await rsaDecrypt(food_intolerance, privateKey)
        decryptedData.medication_allowed = await rsaDecrypt(medication_allowed, privateKey)
        decryptedData.medication = await rsaDecrypt(medication, privateKey)
        decryptedData.friend_1 = await rsaDecrypt(friend_1, privateKey)
        decryptedData.friend_2 = await rsaDecrypt(friend_2, privateKey)
        decryptedData.siblings_in_same_tent = await rsaDecrypt(siblings_in_same_tent, privateKey)
        decryptedData.vaccination_fsme_year = await rsaDecrypt(vaccination_fsme_year, privateKey)
        decryptedData.additional_details = await rsaDecrypt(additional_details, privateKey)
        decryptedData.is_fee_paid = await rsaDecrypt(is_fee_paid, privateKey)
        decryptedData.fee_amount =  await rsaDecrypt(fee_amount, privateKey)
        decryptedData.agreement_signed = await rsaDecrypt(agreement_signed, privateKey)
        decryptedData.media_allowed  =  await rsaDecrypt(media_allowed, privateKey)
        decryptedData.confirmation_email_sent =  await rsaDecrypt(confirmation_email_sent, privateKey)
        decryptedData.is_archived = await rsaDecrypt(is_archived, privateKey);
        decryptedData.payment_date = await rsaDecrypt(payment_date, privateKey);
        // downloaded_consent_form: await rsaDecrypt(downloaded_consent_form, privateKey),
      
    
        return decryptedData;
    }catch(error) {
        return {
            error,
            isDecrypted: false
        }
    }
}