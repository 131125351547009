import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { encryptFormData } from '../utils/crypt-module/encrypt';
import { dashboardActions } from '../redux-utils/actions/dashboard-actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LanguageSwitch from './extra/LanguageSwitch';
import { useTranslation } from 'react-i18next';

const { participantCreateAction } = dashboardActions;

function RegistrationForm(props) {
    const { participantCreateDispatchAction, participantCreateState } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t } = useTranslation();

    const ReactSwal = withReactContent(Swal);

    const RegisterInputObj = {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        father: '',
        mother: '',
        city: '',
        street: '',
        house_number: '',
        zip_code: '',
        mobile: '',
        landline: '',
        email: '',
        fallback_contact: '',
        fallback_number: '',
        swimming_allowed: '',
        swimming_capability: '',
        swimming_badge: '',
        tools_allowed: '',
        vaccination_tetanus: '',
        vaccination_tetanus_year: '',
        vaccination_fsme: '',
        vaccination_fsme_year: '',
        tick_removal_allowed: '',
        allergies: '',
        vegetarian: '',
        food_intolerance: '',
        medication_allowed: '',
        medication: '',
        friend_1: '',
        friend_2: '',
        siblings_in_same_tent: '',
        additional_details: '',
        downloaded_consent_form: '',
        is_fee_paid: '',
        fee_amount: '',
        payment_date: '',
        agreement_signed: '',
        media_allowed: '',
        confirmation_email_sent: '',
    };

    const optional_fields = [
        'friend_1',
        'friend_2',
        'father',
        'mother',
        'allergies',
        'food_intolerance',
        'medication',
        'is_fee_paid',
        'fee_amount',
        'payment_date',
        'agreement_signed',
        'media_allowed',
        'confirmation_email_sent',
        'download_consent_form',
        'vaccination_tetanus_year',
        'vaccination_fsme_year',
        'additional_details',
        'siblings_in_same_tent',
    ];
    const consent_form_path = process.env.REACT_APP_CONSENT_FORM_PATH;

    const [registerInputValues, setRegisterInputValues] =
        useState(RegisterInputObj);
    const [errors, setErrors] = useState({});
    const [downloadConsentChecked, setDownloadConsentChecked] = useState('no');
    const [showVaccinationTetanusYear, setShowVaccinationTetanusYear] =
        useState(false);
    const [showVaccinationFSMEYear, setShowVaccinationFSMEYear] =
        useState(false);

    const handleInputChange = (e) => {
        let { name, value, checked } = e.target;
        const isEmpty = value.trim() === '';
        // const fieldName = name.includes('_') ? name.replace(/_/g, ' ') : name;

        if (name === 'downloaded_consent_form') {
            const status = checked ? 'yes' : 'no';
            setDownloadConsentChecked(status);
        }

        setRegisterInputValues((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Validation logic
        if (name === 'vaccination_fsme') {
            if (value === 'yes') {
                setShowVaccinationFSMEYear(true);
            } else {
                setShowVaccinationFSMEYear(false);
            }
            setErrors((prevError) => ({
                ...prevError,
                [name]: isEmpty
                    ? `${t(
                          'onboarding-form-placeholders.This field is required'
                      )}`
                    : '',
                vaccination_fsme_year:
                    value === 'yes' &&
                    registerInputValues.vaccination_fsme_year.trim() === ''
                        ? `${t(
                              'onboarding-form-placeholders.Vaccination FSME year is required'
                          )}`
                        : '',
            }));
        } else if (name === 'vaccination_fsme_year') {
            setErrors((prevError) => ({
                ...prevError,
                [name]:
                    registerInputValues.vaccination_fsme === 'yes' && isEmpty
                        ? `${t(
                              'onboarding-form-placeholders.Vaccination FSME year is required'
                          )}`
                        : '',
            }));
        } else if (name === 'vaccination_tetanus') {
            if (value === 'yes') {
                setShowVaccinationTetanusYear(true);
            } else {
                setShowVaccinationTetanusYear(false);
            }
            setErrors((prevError) => ({
                ...prevError,
                [name]: isEmpty
                    ? `${t(
                          'onboarding-form-placeholders.This field is required'
                      )}`
                    : '',
                vaccination_tetanus_year:
                    value === 'yes' &&
                    registerInputValues.vaccination_tetanus_year.trim() === ''
                        ? `${t(
                              'onboarding-form-placeholders.Vaccination Tetanus year is required'
                          )}`
                        : '',
            }));
        } else if (name === 'vaccination_tetanus_year') {
            setErrors((prevError) => ({
                ...prevError,
                [name]:
                    registerInputValues.vaccination_tetanus === 'yes' && isEmpty
                        ? `${t(
                              'onboarding-form-placeholders.Vaccination Tetanus year is required'
                          )}`
                        : '',
            }));
        } else if (optional_fields.includes(name)) {
            setErrors((prevError) => ({
                ...prevError,
                [name]: '',
            }));
        } else {
            setErrors((prevError) => ({
                ...prevError,
                [name]: isEmpty
                    ? `${t(
                          'onboarding-form-placeholders.This field is required'
                      )}`
                    : '',
            }));
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        Object.keys(registerInputValues).forEach((key) => {
            if (
                !optional_fields.includes(key) &&
                registerInputValues[key].trim() === ''
            ) {
                newErrors[key] = `${t(
                    'onboarding-form-placeholders.This field is required'
                )}`;
            }
        });

        if (
            registerInputValues.vaccination_fsme === 'yes' &&
            registerInputValues.vaccination_fsme_year.trim() === ''
        ) {
            newErrors.vaccination_fsme_year = `${t(
                'onboarding-form-placeholders.This field is required'
            )}`;
        }

        if (
            registerInputValues.vaccination_tetanus === 'yes' &&
            registerInputValues.vaccination_tetanus_year.trim() === ''
        ) {
            newErrors.vaccination_tetanus_year = `${t(
                'onboarding-form-placeholders.This field is required'
            )}`;
        }

        setErrors(newErrors);

        //sweet alert can be used here
        if (Object.values(newErrors).some((error) => error !== '')) {
            ReactSwal.fire({
                title: t('onboarding-form-placeholders.Error'),
                text: t('onboarding-form-placeholders.Kindly fill all fields'),
                icon: 'error',
                confirmButtonText: 'OK',
            });

            return;
        }

        const data = {
            ...registerInputValues,
            is_fee_paid: 'no',
            fee_amount: 'standard',
            agreement_signed: 'no',
            media_allowed: 'no',
            confirmation_email_sent: 'no',
            is_archived: 'no',
        };

        const params = {
            publicKey: process.env.REACT_APP_PUB_KEY_1,
            formData: data,
        };

        // encrypt data here
        encryptFormData(params).then((encryptedData) => {
            // dispatch to redux for posting here
            participantCreateDispatchAction(encryptedData);
            setIsSubmitting(true);
        });
    };

    useEffect(() => {
        if (
            participantCreateState.participantCreateStatus ===
            'participant_create_success'
        ) {
            setIsSubmitting(false);
            ReactSwal.fire({
                title: `${t('onboarding-form-placeholders.Success')}`,
                text: `${t(
                    'onboarding-form-placeholders.Registration was successful'
                )}`,
                icon: 'success',
            }).then(() => {
                window.location.reload();
            });
        }
        if (
            participantCreateState.participantCreateStatus ===
            'participant_create_failed'
        ) {
            setIsSubmitting(false);
            ReactSwal.fire({
                title: `${t('onboarding-form-placeholders.Error')}`,
                text: `${t(
                    'onboarding-form-placeholders.There was a problem submitting your form'
                )}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    }, [participantCreateState.participantCreateStatus]);

    return (
        <div className="flex flex-col max-w-full pb-10 md:pb-0">
            <div className="shadow-sm py-8 px-2 md:px-8 mb-10">
                <div className="mb-6">
                    <h2 className="font-semibold text-2xl md:text-3xl text-gray-800 leading-tight">
                        {t('onboarding-form.Participant Registration')}
                    </h2>
                </div>
                <form
                    className="w-full flex flex-col md:gap-8 px-3 md:px-0"
                    onSubmit={handleFormSubmit}
                >
                    <div
                        data-step="1"
                        className="w-full flex flex-col justify-between items-center"
                    >
                        {/* form */}
                        <div className="rounded-md flex flex-col w-full md:gap-8 mx-auto">
                            <div className="h-[27px] flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t(
                                        'onboarding-form.Participant Information'
                                    )}
                                </span>
                            </div>
                            <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                <div className="flex flex-col md:flex-row gap-3 items-start">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.First Name'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="first_name"
                                            value={
                                                registerInputValues.first_name
                                            }
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input First Name'
                                            )}`}
                                        />
                                        {errors.first_name && (
                                            <span className="block mt-1 text-sm text-red-500">
                                                {errors.first_name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Last Name')}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            name="last_name"
                                            onChange={handleInputChange}
                                            value={
                                                registerInputValues.last_name
                                            }
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Last Name'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.last_name ? 'h-5' : ''
                                            }
                                        >
                                            {errors.last_name && (
                                                <span className="block mt-1 text-sm text-red-500">
                                                    {errors.last_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row gap-3 items-start">
                                    <div className=" flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Date of Birth'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="date"
                                            onChange={handleInputChange}
                                            value={
                                                registerInputValues.date_of_birth
                                            }
                                            name="date_of_birth"
                                            id="dateOfBirth"
                                            className="h-10 rounded-[4px] border-solid w-full border-2 border-stroke p-2 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder="Select date"
                                        />
                                        <div
                                            className={
                                                errors.date_of_birth
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.date_of_birth && (
                                                <span className="block text-sm text-red-500">
                                                    {errors.date_of_birth}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto ">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Gender')}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <select
                                            value={registerInputValues.gender}
                                            onChange={handleInputChange}
                                            name="gender"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-2 text-secondary focus:border-primary focus:border-2 focus:outline-none"
                                        >
                                            <option
                                                value=""
                                                className="font-normal text-sm text-secondary"
                                            >
                                                {`${t(
                                                    'onboarding-form-placeholders.Select Gender'
                                                )}`}
                                            </option>
                                            <option value="Male">
                                                {t(
                                                    'onboarding-form-placeholders.Male'
                                                )}
                                            </option>
                                            <option value="Female">
                                                {t(
                                                    'onboarding-form-placeholders.Female'
                                                )}
                                            </option>
                                            {/* Add more options as needed */}
                                        </select>
                                        <div
                                            className={
                                                errors.gender ? 'h-5' : ''
                                            }
                                        >
                                            {errors.gender && (
                                                <span className="block text-sm text-red-500">
                                                    {errors.gender}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />

                    <div
                        data-step="2"
                        className="w-full  flex flex-col justify-between items-center "
                    >
                        {/* form */}
                        <div className="rounded-md flex flex-col w-full md:gap-8 mt-2 md:mt-0 mx-auto">
                            <div className="h-[27px] flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t('onboarding-form.Guardian Information')}
                                </span>
                            </div>
                            <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                <div className="flex flex-col md:flex-row gap-3 items-start">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    "onboarding-form.Father's Full Name"
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            value={registerInputValues.father}
                                            name="father"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                "onboarding-form-placeholders.Input Father's Full Name"
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.father ? 'h-5' : ''
                                            }
                                        >
                                            {errors.father && (
                                                <span className="block text-sm text-red-500">
                                                    {errors.father}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    "onboarding-form.Mother's Full Name"
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={registerInputValues.mother}
                                            name="mother"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                "onboarding-form-placeholders.Input Mother's Full Name"
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.mpther ? 'h-5' : ''
                                            }
                                        >
                                            {errors.mother && (
                                                <span className="block text-sm text-red-500">
                                                    {errors.mother}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-3 items-center">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.City')}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={registerInputValues.city}
                                            name="city"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input City'
                                            )}`}
                                        />
                                        <div
                                            className={errors.city ? 'h-5' : ''}
                                        >
                                            {errors.city && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.city}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col gap-2 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="h-[21px] font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Street')}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={registerInputValues.street}
                                            name="street"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Street'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.street ? 'h-5' : ''
                                            }
                                        >
                                            {errors.street && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.street}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-3 items-center">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.House Number'
                                                )}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={
                                                registerInputValues.house_number
                                            }
                                            name="house_number"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input House Number'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.house_number ? 'h-5' : ''
                                            }
                                        >
                                            {errors.house_number && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.house_number}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Zip Code')}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={registerInputValues.zip_code}
                                            name="zip_code"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Zip Code'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.zip_code ? 'h-5' : ''
                                            }
                                        >
                                            {errors.zip_code && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.zip_code}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-3 items-center">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Mobile Number'
                                                )}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="tel"
                                            value={registerInputValues.mobile}
                                            name="mobile"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Mobile Number'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.mobile ? 'h-5' : ''
                                            }
                                        >
                                            {errors.mobile && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.mobile}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Landline')}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="tel"
                                            value={registerInputValues.landline}
                                            name="landline"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Landline'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.landline ? 'h-5' : ''
                                            }
                                        >
                                            {errors.landline && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.landline}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-3 items-center md:w-[50%]">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-[50%] ">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Email address'
                                                )}
                                            </span>

                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="email"
                                            value={registerInputValues.email}
                                            name="email"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Email Address'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.email ? 'h-5' : ''
                                            }
                                        >
                                            {errors.email && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.email}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end form */}
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-7 md:my-2" />
                    <div
                        data-step="3"
                        className="w-full  flex flex-col justify-between items-center "
                    >
                        {/* form */}
                        <div className="rounded-md flex flex-col w-full md:gap-8 mt-2 md:mt-0 mx-auto">
                            <div className="flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t(
                                        'onboarding-form.Emergency Contact Information'
                                    )}
                                </span>
                            </div>

                            <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                <div className="flex flex-col md:flex-row gap-3 items-start">
                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Full Name')}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            value={
                                                registerInputValues.fallback_contact
                                            }
                                            name="fallback_contact"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Full Name'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.fallback_contact
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.fallback_contact && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.fallback_contact}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Telephone Number'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="tel"
                                            value={
                                                registerInputValues.fallback_number
                                            }
                                            name="fallback_number"
                                            onChange={handleInputChange}
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Telephone Number'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.fallback_number
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.fallback_number && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.fallback_number}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />
                    <div
                        data-step="4"
                        className="w-full  flex flex-col justify-between items-center "
                    >
                        {/* form */}
                        <div className="rounded-md flex flex-col w-full md:gap-8 mt-2 md:mt-0 mx-auto">
                            <div className="h-[27px] flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t('onboarding-form.Activity Information')}
                                </span>
                            </div>
                            <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                <div className="flex flex-col md:flex-row gap-3 items-start">
                                    <div className=" flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Swimming Allowed'
                                                )}
                                                ?
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="swimming-allowed-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    value="yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.swimming_allowed ===
                                                        'yes'
                                                    }
                                                    type="radio"
                                                    id="swimming-allowed-yes"
                                                    name="swimming_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="swimming-allowed-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.swimming_allowed ===
                                                        'no'
                                                    }
                                                    id="swimming-allowed-no"
                                                    name="swimming_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.swimming_allowed
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.swimming_allowed && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.swimming_allowed}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-1 w-full md:w-auto">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Swimming Capability'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="swimming-capacity-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    value="yes"
                                                    type="radio"
                                                    id="swimming-capacity-yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.swimming_capability ===
                                                        'yes'
                                                    }
                                                    name="swimming_capability"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="swimming-capacity-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    id="swimming-capacity-no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.swimming_capability ===
                                                        'no'
                                                    }
                                                    name="swimming_capability"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.swimming_capability
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.swimming_capability && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.swimming_capability}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-3 md:items-center">
                                    <div className="flex flex-1 flex-col gap-1">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Swimming Badge'
                                                )}
                                                ?
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <select
                                            id="swimmingBadge"
                                            name="swimming_badge"
                                            onChange={handleInputChange}
                                            className="rounded-[4px] h-10 flex-1 border-solid border-2 border-stroke p-2 text-secondary focus:border-primary focus:border-2 focus:outline-none"
                                        >
                                            <option
                                                value=""
                                                className="font-normal text-sm text-secondary"
                                            >
                                                {`${t(
                                                    'onboarding-form-placeholders.Select Swimming Badge'
                                                )}`}
                                            </option>
                                            <option
                                                value="Fruehschwimmer (Seepferdchen)"
                                                className="font-normal text-sm text-secondary"
                                            >
                                                Frühschwimmer (Seepferdchen)
                                            </option>
                                            <option
                                                value="Freischwimmer (Bronze)"
                                                className="font-normal text-sm text-secondary"
                                            >
                                                Freischwimmer (Bronze)
                                            </option>
                                            <option
                                                value="Silber"
                                                className="font-normal text-sm text-secondary"
                                            >
                                                Silber
                                            </option>
                                            <option
                                                value="Gold"
                                                className="font-normal text-sm text-secondary"
                                            >
                                                Gold
                                            </option>
                                            <option
                                                value="Keines davon"
                                                className="font-normal text-sm text-secondary"
                                            >
                                                Keines davon
                                            </option>
                                            {/* Add more options as needed */}
                                        </select>
                                        <div
                                            className={
                                                errors.swimming_allowed
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.swimming_badge && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.swimming_badge}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-1">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Tools Allowed'
                                                )}
                                                ?
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="tools-allowed-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    id="tools-allowed-yes"
                                                    name="tools_allowed"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.tools_allowed ===
                                                        'yes'
                                                    }
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                for="tools-allowed-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    type="radio"
                                                    id="tools-allowed-no"
                                                    value="no"
                                                    name="tools_allowed"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.tools_allowed ===
                                                        'no'
                                                    }
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.tools_allowed
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.tools_allowed && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.tools_allowed}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end form */}
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />

                    <div
                        data-step="5"
                        className="w-full  flex flex-col justify-between items-center"
                    >
                        {/* form */}
                        <div className="rounded-md  flex flex-col w-full md:gap-8   mx-auto">
                            <div className="h-[27px] flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t('onboarding-form.Health Information')}
                                </span>
                            </div>
                            <div className="flex flex-col gap-10">
                                <div className="flex flex-wrap gap-3">
                                    <div className="flex w-full sm:w-auto sm:flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Vaccination Tetanus'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="vaccination-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    value="yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vaccination_tetanus ===
                                                        'yes'
                                                    }
                                                    type="radio"
                                                    id="vaccination-yes"
                                                    name="vaccination_tetanus"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {' '}
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                for="vaccination-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    value="no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vaccination_tetanus ===
                                                        'no'
                                                    }
                                                    type="radio"
                                                    id="vaccination-no"
                                                    name="vaccination_tetanus"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {' '}
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.vaccination_tetanus
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.vaccination_tetanus && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.vaccination_tetanus}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {showVaccinationTetanusYear && (
                                        <div className="flex w-full sm:w-auto sm:flex-1 flex-col gap-2">
                                            <div className="flex items-center gap-1">
                                                <span className="font-semibold text-sm text-secondary">
                                                    {t(
                                                        'onboarding-form.Vaccination Tetanus Year'
                                                    )}
                                                </span>
                                            </div>

                                            <div className="flex justify-between items-center gap-3">
                                                <div className="relative flex-1">
                                                    <input
                                                        type="number"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        name="vaccination_tetanus_year"
                                                        min="1900"
                                                        max="2100"
                                                        step="1"
                                                        className="h-9 rounded-[4px] border-solid w-full border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                                        placeholder="YYYY"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    errors.vaccination_tetanus_year
                                                        ? 'h-5'
                                                        : 'hidden'
                                                }
                                            >
                                                {errors.vaccination_tetanus_year && (
                                                    <span
                                                        className={
                                                            errors.vaccination_tetanus_year
                                                                ? 'block text-sm text-red-500'
                                                                : ''
                                                        }
                                                    >
                                                        {
                                                            errors.vaccination_tetanus_year
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="flex flex-wrap gap-3">
                                    <div className="flex w-full sm:w-auto sm:flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Vaccination FSME'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="vaccination-fsme-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    value="yes"
                                                    type="radio"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vaccination_fsme ===
                                                        'yes'
                                                    }
                                                    id="vaccination-fsme-yes"
                                                    name="vaccination_fsme"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="vaccination-fsme-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2 "
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    id="vaccination-fsme-no"
                                                    name="vaccination_fsme"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vaccination_fsme ===
                                                        'no'
                                                    }
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="h-5">
                                            {errors.vaccination_fsme && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.vaccination_fsme}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {showVaccinationFSMEYear && (
                                        <div className="flex w-full sm:w-auto sm:flex-1 flex-col gap-2">
                                            <div className="flex items-center gap-1">
                                                <span className="font-semibold text-sm text-secondary">
                                                    {t(
                                                        'onboarding-form.Vaccination FSME Year'
                                                    )}
                                                </span>
                                            </div>

                                            <div className="flex justify-between items-center gap-3">
                                                <div className="relative flex-1">
                                                    <input
                                                        type="number"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        name="vaccination_fsme_year"
                                                        min="1900"
                                                        max="2100"
                                                        step="1"
                                                        className="h-10 rounded-[4px] border-solid w-full border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                                        placeholder="YYYY"
                                                    />
                                                </div>
                                            </div>
                                            <div className="h-5">
                                                {errors.vaccination_fsme_year && (
                                                    <span className="block mt-2 text-sm text-red-500">
                                                        {
                                                            errors.vaccination_fsme_year
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Tick Removal Allowed'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="tick-removal-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.tick_removal_allowed ===
                                                        'yes'
                                                    }
                                                    id="tick-removal-yes"
                                                    name="tick_removal_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="tick-removal-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.tick_removal_allowed ===
                                                        'no'
                                                    }
                                                    id="tick-removal-no"
                                                    name="tick_removal_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="h-5">
                                            {errors.tick_removal_allowed && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {
                                                        errors.tick_removal_allowed
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Allergies')}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="allergies"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Allergies'
                                            )}`}
                                        />
                                        <div className="h-5">
                                            {errors.allergies && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.allergies}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Vegetarian'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                htmlFor="vegetarian-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    id="vegetarian-yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vegetarian ===
                                                        'yes'
                                                    }
                                                    value="yes"
                                                    name="vegetarian"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="vegetarian-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    id="vegetarian-no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.vegetarian ===
                                                        'no'
                                                    }
                                                    value="no"
                                                    name="vegetarian"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="h-5">
                                            {errors.vegetarian && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.vegetarian}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Food Intolerance'
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="food_intolerance"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Food Tolerance'
                                            )}`}
                                        />
                                        <div className="h-5">
                                            {errors.food_intolerance && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.food_intolerance}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                    <div className="flex flex-col gap-3">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Medication Allowed'
                                                )}
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                for="medication-allowed-yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.medication_allowed ===
                                                        'yes'
                                                    }
                                                    id="medication-allowed-yes"
                                                    name="medication_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="medication-allowed-no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.medication_allowed ===
                                                        'no'
                                                    }
                                                    id="medication-allowed-no"
                                                    name="medication_allowed"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.medication_allowed
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.medication_allowed && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.medication_allowed}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Medication'
                                                )}
                                            </span>
                                            <span className="text-primary font-bold">
                                                *
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="medication"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Medication'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.medication ? 'h-5' : ''
                                            }
                                        >
                                            {errors.medication && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.medication}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Additional Details'
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="additional_details"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Additional Details'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.additional_details
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.additional_details && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.additional_details}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />
                    <div
                        data-step="6"
                        className="w-full  flex flex-col justify-between items-center "
                    >
                        {/* form */}
                        <div className="rounded-md  flex flex-col w-full gap-4  mx-auto">
                            <div className="flex gap-2">
                                <span className="text-tertiary font-semibold text-[16px]">
                                    {t('onboarding-form.Other')}
                                </span>
                            </div>
                            <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                <div className="flex flex-col md:flex-row gap-3">
                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Friend 1')}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="friend_1"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Friend Name'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.friend_1 ? 'h-5' : ''
                                            }
                                        >
                                            {errors.friend_1 && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.friend_1}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t('onboarding-form.Friend 2')}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="friend_2"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Friend Name'
                                            )}`}
                                        />
                                        <div
                                            className={
                                                errors.friend_2 ? 'h-5' : ''
                                            }
                                        >
                                            {errors.friend_2 && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.friend_2}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-3 md:gap-8 pl-2 md:pl-0 mt-2 md:mt-0">
                                    <div className="flex flex-col gap-3">
                                        <div className="flex items-center gap-1">
                                            <span className="font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Sibling in Same Tent'
                                                )}
                                            </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <label
                                                for="siblings_in_same_tent_yes"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.siblings_in_same_tent ===
                                                        'yes'
                                                    }
                                                    id="siblings_in_same_tent_yes"
                                                    name="siblings_in_same_tent"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.Yes'
                                                    )}
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="siblings_in_same_tent_no"
                                                className="flex-1 border border-stroke py-2 flex items-center px-8 gap-2"
                                            >
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    onChange={handleInputChange}
                                                    checked={
                                                        registerInputValues.siblings_in_same_tent ===
                                                        'no'
                                                    }
                                                    id="siblings_in_same_tent_no"
                                                    name="siblings_in_same_tent"
                                                />
                                                <span className="text-secondary opacity-60 font-normal text-sm">
                                                    {t(
                                                        'onboarding-form-placeholders.No'
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                errors.siblings_in_same_tent
                                                    ? 'h-5'
                                                    : ''
                                            }
                                        >
                                            {errors.siblings_in_same_tent && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {
                                                        errors.siblings_in_same_tent
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="flex flex-1 flex-col gap-2">
                                        <div className="flex items-center gap-1">
                                            <span className="h-[21px] font-semibold text-sm text-secondary">
                                                {t(
                                                    'onboarding-form.Medication'
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={handleInputChange}
                                            name="medication"
                                            className="h-10 rounded-[4px] border-solid border-2 border-stroke p-3 placeholder:text-sm placeholder:text-secondary placeholder:opacity-60 placeholder:font-[400] focus:border-primary focus:border-2 focus:outline-none"
                                            placeholder={`${t(
                                                'onboarding-form-placeholders.Input Medication'
                                            )}`}
                                        />
                                        <div className={errors.medication ? "h-5" : ""}>
                                            {errors.medication && (
                                                <span className="block mt-2 text-sm text-red-500">
                                                    {errors.medication}
                                                </span>
                                            )}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {/* end form */}
                    </div>
                    <hr className="w-full border-0.5 border-secondary opacity-10 my-2" />
                    <div
                        data-step="7"
                        className="w-full  flex flex-col justify-between items-center "
                    >
                        {/* form  */}
                        <div className="rounded-md flex flex-col w-full md:gap-8 mx-auto">
                            <div className="h-[27px] flex gap-2">
                                <span className="text-secondary font-semibold text-[16px]">
                                    {t('onboarding-form.Important')}!
                                </span>
                            </div>
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-3 items-center">
                                    <p className="font-normal text-[16px]">
                                        {t('onboarding-form.terms-condition')}
                                    </p>
                                </div>

                                <div className="font-normal text-[16px]">
                                    {`${t(
                                        'onboarding-form.You can download the consent form'
                                    )} `}
                                    <a
                                        href={`${consent_form_path}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="text-primary"
                                    >
                                        {`  ${t('onboarding-form.here')} `}
                                    </a>
                                    {t('onboarding-form.as a PDF file')}.
                                </div>

                                <div className="flex gap-3">
                                    <label
                                        for=""
                                        className="font-normal text-[16px] flex items-center"
                                    >
                                        <input
                                            required
                                            id="downloaded_consent_form"
                                            name="downloaded_consent_form"
                                            checked={
                                                downloadConsentChecked === 'yes'
                                            }
                                            value={downloadConsentChecked}
                                            onChange={handleInputChange}
                                            type="checkbox"
                                            className=" checked:border-primary"
                                        />
                                        <label
                                            htmlFor="downloaded_consent_form"
                                            className="ml-2"
                                        >
                                            {t(
                                                'onboarding-form.I have downloaded the consent form'
                                            )}
                                            .
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end w-full gap-3">
                            <button
                                disabled={isSubmitting}
                                className="h-10 bg-primary cursor-pointer rounded-full px-6 py-2 gap-2.5 flex items-center justify-center font-semibold text-sm text-white self-end"
                                type="submit"
                            >
                                {t('onboarding-form.Send')}
                            </button>
                        </div>

                        {/* end form */}
                    </div>
                </form>
            </div>
            <div className="pl-5 md:pl-0">
                <LanguageSwitch bgColor={`primary`} />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    participantCreateState: state.dashboardState,
});

const mapDispatchToProps = (dispatch) => ({
    participantCreateDispatchAction: (params) =>
        dispatch(participantCreateAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
