async function importKey ( format, keyData, algorithm, extractable, keyUsages )
{
  return crypto.subtle.importKey(format, keyData, algorithm, extractable, keyUsages);
}

async function rsaEncrypt ( data, key )
{
  var keyObject = await importKey('spki', base64ToUint8Array(key), { name: "RSA-OAEP", hash: { name: "SHA-256" } }, true, ['encrypt']);
  var encodedData = await crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
      hash: { name: 'SHA-256' }
    },
    keyObject,
    encodeData(data)
  );
  return Uint8ArrayToBase64(encodedData);
}


function stringToUint8Array ( data )
{
  return Uint8Array.from(data, c => c.charCodeAt(0));
}

function Uint8ArrayToString ( data )
{
  return String.fromCharCode.apply(null, data);
}

function binaryToBase64 ( data )
{
  return btoa(data);
}

function base64ToBinary ( data )
{
  return atob(data);
}

function encodeData ( data )
{
  return new TextEncoder('utf-8').encode(data);
}

function decodeData ( data )
{
  return new TextDecoder('utf-8').decode(data);
}

function base64ToUint8Array ( key )
{
  const binaryKey       = base64ToBinary(key);
  const Uint8ArrayKey   = stringToUint8Array(binaryKey);
  return Uint8ArrayKey;
}

function Uint8ArrayToBase64 ( data )
{
  const stringData = Uint8ArrayToString(new Uint8Array(data));
  const base64Data = binaryToBase64(stringData);
  return base64Data;
}

function getDatetime()
{
  // Create a new Date object
  var currentDate = new Date();

  // Extract the date components
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1; // Months are zero-based, so January is 0
  var year = currentDate.getFullYear();

  // Extract the time components
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();

  // Construct the date string
  var datetime = '' + year + month + day + hours + minutes + seconds;

  // Display the date and time
  return datetime;
}

function getSelectedRadioValue(groupName) {
  const radioButtons = document.querySelectorAll(`input[type="radio"][name="${groupName}"]:checked`);
  return radioButtons.length > 0 ? radioButtons[0].value : '';
}



// SECTION TO SUBMIT FORM HERE
// async function SubmitForm(event) {
//   event.preventDefault();
//   const publicKey  = publicKeyGenerated;
 

//   try {
//       const encryptedFormData = await encryptFormData(publicKey);
//       sendFormDataToServer(encryptedFormData);
//   } catch (error) {
//       console.error('Error', error);
//       Swal.fire({
//         title: 'Error!',
//         text: 'Something went wrong',
//         icon: 'error',  
//         confirmButtonText: 'OK'
//      });
//   }
// }


export async function encryptFormData(params) {

  const { publicKey, formData } = params;

  //Get form data
  const  first_name               = formData.first_name
  const  last_name                = formData.last_name
  const  date_of_birth            = formData.date_of_birth
  const  gender                   = formData.gender
  const  father                   = formData.father
  const  mother                   = formData.mother
  const  city                     = formData.city
  const  street                   = formData.street
  const  house_number             = formData.house_number
  const  zip_code                 = formData.zip_code
  const  mobile                   = formData.mobile
  const  landline                 = formData.landline
  const  email                    = formData.email
  const  fallback_contact         = formData.fallback_contact
  const  fallback_number          = formData.fallback_number
  const  swimming_allowed         = formData.swimming_allowed
  const  swimming_capability      = formData.swimming_capability
  const  swimming_badge           = formData.swimming_badge
  const  tools_allowed            = formData.tools_allowed
  const  vaccination_tetanus      = formData.vaccination_tetanus
  const  vaccination_tetanus_year = formData.vaccination_tetanus_year
  const  vaccination_fsme         = formData.vaccination_fsme
  const  vaccination_fsme_year    = formData.vaccination_fsme_year
  const  tick_removal_allowed     = formData.tick_removal_allowed
  const  allergies                = formData.allergies
  const  vegetarian               = formData.vegetarian
  const  food_intolerance         = formData.food_intolerance
  const  medication_allowed       = formData.medication_allowed
  const  medication               = formData.medication
  const  friend_1                 = formData.friend_1
  const  friend_2                 = formData.friend_2
  const  siblings_in_same_tent    = formData.siblings_in_same_tent
  const  additional_details       = formData.additional_details
  const  downloaded_consent_form  = formData.downloaded_consent_form
  const is_fee_paid               = formData.is_fee_paid;
  const fee_amount                = formData.fee_amount;
  const agreement_signed          = formData.agreement_signed;
  const media_allowed             = formData.media_allowed;
  const confirmation_email_sent   = formData.confirmation_email_sent;
  const is_archived               = formData.is_archived;
  const payment_date              = formData.payment_date;

  // Encrypt each form value

  const encryptedData = {
    first_name         : await rsaEncrypt(first_name, publicKey),
    last_name          : await rsaEncrypt(last_name, publicKey),
    date_of_birth      : await rsaEncrypt(date_of_birth, publicKey),
    gender             : await rsaEncrypt(gender, publicKey),
    father             : await rsaEncrypt(father, publicKey),
    mother             : await rsaEncrypt(mother, publicKey),
    city               : await rsaEncrypt(city, publicKey),
    street             : await rsaEncrypt(street, publicKey),
    house_number       : await rsaEncrypt(house_number, publicKey),
    zip_code           : await rsaEncrypt(zip_code, publicKey),
    mobile             : await rsaEncrypt(mobile, publicKey),
    landline           : await rsaEncrypt(landline, publicKey),
    email              : await rsaEncrypt(email, publicKey),
    fallback_contact   : await rsaEncrypt(fallback_contact, publicKey),
    fallback_number    : await rsaEncrypt(fallback_number, publicKey),
    swimming_allowed   : await rsaEncrypt(swimming_allowed, publicKey),
    swimming_capability: await rsaEncrypt(swimming_capability, publicKey),
    swimming_badge     : await rsaEncrypt(swimming_badge, publicKey),
    tools_allowed      : await rsaEncrypt(tools_allowed, publicKey),
    vaccination_tetanus: await rsaEncrypt(vaccination_tetanus, publicKey),
    vaccination_tetanus_year: await rsaEncrypt(vaccination_tetanus_year, publicKey),
    vaccination_fsme     : await rsaEncrypt(vaccination_fsme, publicKey),
    vaccination_fsme_year: await rsaEncrypt(vaccination_fsme_year, publicKey),
    tick_removal_allowed : await rsaEncrypt(tick_removal_allowed, publicKey),
    allergies            : await rsaEncrypt(allergies, publicKey),
    vegetarian           : await rsaEncrypt(vegetarian, publicKey),
    food_intolerance     : await rsaEncrypt(food_intolerance, publicKey),
    medication_allowed   : await rsaEncrypt(medication_allowed, publicKey),
    medication           : await rsaEncrypt(medication, publicKey),
    friend_1             : await rsaEncrypt(friend_1, publicKey),
    friend_2             : await rsaEncrypt(friend_2, publicKey),
    siblings_in_same_tent: await rsaEncrypt(siblings_in_same_tent, publicKey),
    additional_details   : await rsaEncrypt(additional_details, publicKey),
    downloaded_consent_form   : await rsaEncrypt(downloaded_consent_form, publicKey),
    is_fee_paid          : await rsaEncrypt(is_fee_paid, publicKey),
    fee_amount           : await rsaEncrypt(fee_amount, publicKey),
    agreement_signed     : await rsaEncrypt(agreement_signed, publicKey),
    media_allowed        : await rsaEncrypt(media_allowed, publicKey),
    confirmation_email_sent : await rsaEncrypt(confirmation_email_sent , publicKey),
    is_archived          : await rsaEncrypt(is_archived , publicKey),
    payment_date          : await rsaEncrypt(payment_date , publicKey),
};

  return encryptedData;
}


export async function encryptUtility(params) {
  const { publicKey, formData } = params;

  const encryptField = async (fieldValue) => {
    return await rsaEncrypt(fieldValue, publicKey);
  };

  const encryptedData = {};

  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      encryptedData[key] = await encryptField(formData[key]);
    }
  }

  return encryptedData;
}

// SECTION TO SEND FORM DATA TO SERVER
// function sendFormDataToServer(formData) {
//   const token = $("meta[name='csrf-token']").attr("content");
//   const form_data = new FormData();

//   for (const [key, value] of Object.entries(formData)) {
//       form_data.append(key, value);
//   }

//   $.ajax({
//       url: root_path + 'participant/store',
//       method: "POST",
//       data: form_data,
//       processData: false,
//       cache: false,
//       contentType: false,
//       headers: {
//           'X-CSRF-TOKEN': token
//       },
//       beforeSend: function () {
//           document.getElementById("core_preload").style.display = "block";
//           document.getElementById("core_base").style.display = "none";
//       },
//       success: function (response) {
//           document.getElementById("core_preload").style.display = "none";
//           document.getElementById("core_base").style.display = "block";
//           Swal.fire({
//               title: 'Success!',
//               text: 'Your form has been submitted successfully.',
//               icon: 'success',
//           }).then(() => {
//               location.reload();
//           })
//       },
//       error: function (jqXHR, textStatus, errorThrown) {
//           if (jqXHR.status === 422) {
//               var errors = jqXHR.responseJSON.errors;
//               var errorList = '<ul>';
//               for (var field in errors) {
//                   if (errors.hasOwnProperty(field)) {
//                       errors[field].forEach(function (error) {
//                           errorList += '<li>' + error + '</li>';
//                       });
//                   }
//               }
//               errorList += '</ul>';

//               Swal.fire({
//                   title: 'Error!',
//                   html: errorList,
//                   icon: 'error',
//                   confirmButtonText: 'OK'
//               });
//               document.getElementById("core_preload").style.display = "none";
//               document.getElementById("core_base").style.display = "block";
//           } else {
//             document.getElementById("core_preload").style.display = "none";
//             document.getElementById("core_base").style.display = "block";
//               Swal.fire({
//                   title: 'Error!',
//                   text: 'There was a problem submitting your form.',
//                   icon: 'error',
//                   confirmButtonText: 'OK'
//               });
//           }
//       }
//   });
// }