export const Types = [
    {
        actionName: "initialAction",
        types: {
            INITIAL_ACTION: "INITIAL_ACTION"
        }
    },
    {
        actionName: "loginAction",
        types: {
            LOGIN_START: "LOGIN_START",
            LOGIN_SUCCESS: "LOGIN_SUCCESS",
            LOGIN_FAILED: "LOGIN_FAILED",
            LOGIN_STOP: "LOGIN_STOP"
        }
    },
    {
        actionName: "dashboardAction",
        types: {
            ALL_PARTICIPANT_FETCH_START: "ALL_PARTICIPANT_FETCH_START",
            ALL_PARTICIPANT_FETCH_SUCCESS: "ALL_PARTICIPANT_FETCH_SUCCESS",
            ALL_PARTICIPANT_FETCH_SUCCESS_STOP: "ALL_PARTICIPANT_FETCH_SUCCESS_STOP",
            ALL_PARTICIPANT_FETCH_FAILED: "ALL_PARTICIPANT_FETCH_FAILED",
            ALL_PARTICIPANT_FETCH_STOP: "ALL_PARTICIPANT_FETCH_STOP",

            SINGLE_PARTICIPANT_FETCH_START: "SINGLE_PARTICIPANT_FETCH_START",
            SINGLE_PARTICIPANT_FETCH_SUCCESS: "SINGLE_PARTICIPANT_FETCH_SUCCESS",
            SINGLE_PARTICIPANT_FETCH_FAILED: "SINGLE_PARTICIPANT_FETCH_FAILED",
            SINGLE_PARTICIPANT_FETCH_STOP: "SINGLE_PARTICIPANT_FETCH_STOP",

            PARTICIPANT_CREATE_START: "PARTICIPANT_CREATE_START",
            PARTICIPANT_CREATE_SUCCESS: "PARTICIPANT_CREATE_SUCCESS",
            PARTICIPANT_CREATE_FAILED: "PARTICIPANT_CREATE_FAILED",
            PARTICIPANT_CREATE_STOP: "PARTICIPANT_CREATE_STOP",

            PARTICIPANT_UPDATE_START: "PARTICIPANT_UPDATE_START",
            PARTICIPANT_UPDATE_SUCCESS: "PARTICIPANT_UPDATE_SUCCESS",
            PARTICIPANT_UPDATE_FAILED: "PARTICIPANT_UPDATE_FAILED",
            PARTICIPANT_UPDATE_SUCCESS_STOP: "PARTICIPANT_UPDATE_SUCCESS_STOP",

            ENROLLMENT_UPDATE_START: "ENROLLMENT_UPDATE_START",
            ENROLLMENT_UPDATE_SUCCESS: "ENROLLMENT_UPDATE_SUCCESS",
            ENROLLMENT_UPDATE_FAILED: "ENROLLMENT_UPDATE_FAILED",
            ENROLLMENT_UPDATE_SUCCESS_STOP: "ENROLLMENT_UPDATE_SUCCESS_STOP",

            MEDIA_UPDATE_START: "MEDIA_UPDATE_START",
            MEDIA_UPDATE_SUCCESS: "MEDIA_UPDATE_SUCCESS",
            MEDIA_UPDATE_FAILED: "MEDIA_UPDATE_FAILED",
            MEDIA_UPDATE_SUCCESS_STOP: "MEDIA_UPDATE_SUCCESS_STOP",

            MEDICAL_UPDATE_START: "MEDICAL_UPDATE_START",
            MEDICAL_UPDATE_SUCCESS: "MEDICAL_UPDATE_SUCCESS",
            MEDICAL_UPDATE_FAILED: "MEDICAL_UPDATE_FAILED",
            MEDICAL_UPDATE_SUCCESS_STOP: "MEDICAL_UPDATE_SUCCESS_STOP",

            KITCHEN_UPDATE_START: "KITCHEN_UPDATE_START",
            KITCHEN_UPDATE_SUCCESS: "KITCHEN_UPDATE_SUCCESS",
            KITCHEN_UPDATE_FAILED: "KITCHEN_UPDATE_FAILED",
            KITCHEN_UPDATE_SUCCESS_STOP: "KITCHEN_UPDATE_SUCCESS_STOP",

            PARTICIPANT_DELETE_START: "PARTICIPANT_DELETE_START",
            PARTICIPANT_DELETE_SUCCESS: "PARTICIPANT_DELETE_SUCCESS",
            PARTICIPANT_DELETE_FAILED: "PARTICIPANT_DELETE_FAILED",
            PARTICIPANT_DELETE_STOP: "PARTICIPANT_DELETE_STOP",

            PARTICIPANT_ARCHIVE_START: "PARTICIPANT_ARCHIVE_START",
            PARTICIPANT_ARCHIVE_SUCCESS: "PARTICIPANT_ARCHIVE_SUCCESS",
            PARTICIPANT_ARCHIVE_FAILED: "PARTICIPANT_ARCHIVE_FAILED",
            PARTICIPANT_ARCHIVE_SUCCESS_STOP: "PARTICIPANT_ARCHIVE_SUCCESS_STOP",

            PARTICIPANT_UNARCHIVE_START: "PARTICIPANT_UNARCHIVE_START",
            PARTICIPANT_UNARCHIVE_SUCCESS: "PARTICIPANT_UNARCHIVE_SUCCESS",
            PARTICIPANT_UNARCHIVE_FAILED: "PARTICIPANT_UNARCHIVE_FAILED",
            PARTICIPANT_UNARCHIVE_STOP: "PARTICIPANT_UNARCHIVE_STOP"
        }
    },
    {
        actionName: "userAction",
        types: {
            ALL_USER_FETCH_START: "ALL_USER_FETCH_START",
            ALL_USER_FETCH_SUCCESS: "ALL_USER_FETCH_SUCCESS",
            ALL_USER_FETCH_FAILED: "ALL_USER_FETCH_FAILED",
            ALL_USER_FETCH_STOP: "ALL_USER_FETCH_STOP",

            SINGLE_USER_FETCH_START: "SINGLE_USER_FETCH_START",
            SINGLE_USER_FETCH_SUCCESS: "SINGLE_USER_FETCH_SUCCESS",
            SINGLE_USER_FETCH_FAILED: "SINGLE_USER_FETCH_FAILED",
            SINGLE_USER_FETCH_STOP: "SINGLE_USER_FETCH_STOP",

            USER_CREATE_START: "USER_CREATE_START",
            USER_CREATE_SUCCESS: "USER_CREATE_SUCCESS",
            USER_CREATE_FAILED: "USER_CREATE_FAILED",
            USER_CREATE_STOP: "USER_CREATE_STOP",

            USER_UPDATE_START: "USER_UPDATE_START",
            USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
            USER_UPDATE_FAILED: "USER_UPDATE_FAILED",
            USER_UPDATE_STOP: "USER_UPDATE_STOP",

            USER_DELETE_START: "USER_DELETE_START",
            USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
            USER_DELETE_FAILED: "USER_DELETE_FAILED",
            USER_DELETE_STOP: "USER_DELETE_STOP"
        }
    },
    {
        actionName:"sidebarAction",
        types:{
            OPEN_SIDEBAR:"OPEN_SIDEBAR",
            CLOSE_SIDEBAR:"CLOSE_SIDEBAR",
            TOGGLE_SIDEBAR:"TOGGLE_SIDEBAR",
            RESET_SIDEBAR:"RESET_SIDEBAR"
        }
    }
]