import Pages from '../pages';

const { Registration } = Pages();

export const Routes = [
    {
        name: 'registration',
        url: '/',
        component: () => <Registration />,
    },
];
