import { Types as TYPES } from "../types";
import { States as STATES } from "../states";
import Swal from "sweetalert2";

const actionType = TYPES.find(type => type.actionName === "dashboardAction");
const { 
    ALL_PARTICIPANT_FETCH_START, 
    ALL_PARTICIPANT_FETCH_SUCCESS, 
    ALL_PARTICIPANT_FETCH_SUCCESS_STOP, 
    ALL_PARTICIPANT_FETCH_FAILED, 
    ALL_PARTICIPANT_FETCH_STOP,

    SINGLE_PARTICIPANT_FETCH_START, 
    SINGLE_PARTICIPANT_FETCH_SUCCESS, 
    SINGLE_PARTICIPANT_FETCH_FAILED, 
    SINGLE_PARTICIPANT_FETCH_STOP,

    PARTICIPANT_CREATE_START, 
    PARTICIPANT_CREATE_SUCCESS, 
    PARTICIPANT_CREATE_FAILED, 
    PARTICIPANT_CREATE_STOP,

    PARTICIPANT_UPDATE_START, 
    PARTICIPANT_UPDATE_SUCCESS, 
    PARTICIPANT_UPDATE_FAILED, 
    PARTICIPANT_UPDATE_SUCCESS_STOP,

    ENROLLMENT_UPDATE_START,
    ENROLLMENT_UPDATE_SUCCESS,
    ENROLLMENT_UPDATE_FAILED,
    ENROLLMENT_UPDATE_SUCCESS_STOP,

    MEDIA_UPDATE_START,
    MEDIA_UPDATE_SUCCESS,
    MEDIA_UPDATE_FAILED,
    MEDIA_UPDATE_SUCCESS_STOP,

    MEDICAL_UPDATE_START,
    MEDICAL_UPDATE_SUCCESS,
    MEDICAL_UPDATE_FAILED,
    MEDICAL_UPDATE_SUCCESS_STOP,

    KITCHEN_UPDATE_START,
    KITCHEN_UPDATE_SUCCESS,
    KITCHEN_UPDATE_FAILED,
    KITCHEN_UPDATE_SUCCESS_STOP,

    PARTICIPANT_DELETE_START, 
    PARTICIPANT_DELETE_SUCCESS, 
    PARTICIPANT_DELETE_FAILED, 
    PARTICIPANT_DELETE_STOP,


    PARTICIPANT_ARCHIVE_START,
    PARTICIPANT_ARCHIVE_SUCCESS,
    PARTICIPANT_ARCHIVE_FAILED,
    PARTICIPANT_ARCHIVE_SUCCESS_STOP,
    PARTICIPANT_UNARCHIVE_STOP
} = actionType.types;

const reducerState = STATES.find(state => state.reducerName === "dashboardReducer");
const { initialState } = reducerState;

export default function dashboardReducer(state = initialState, action) {

    switch(action.type) {
        case ALL_PARTICIPANT_FETCH_START:
            return {
                ...state,
                status: "all_participant_fetch_start",
            }
        case ALL_PARTICIPANT_FETCH_SUCCESS:
            return {
                ...state,
                status: "all_participant_fetch_success",
                allParticipantData: action.payload
            }
        case ALL_PARTICIPANT_FETCH_SUCCESS_STOP:
            return {
                ...state,
                status: "all_participant_fetch_success_stop",
                allParticipantData: state.allParticipantData
            }
        case ALL_PARTICIPANT_FETCH_FAILED:
            return {
                ...state,
                status: "all_participant_fetch_failed",
                isLoading: false
            }
        case SINGLE_PARTICIPANT_FETCH_START:
            return {
                ...state,
                status: "single_participant_fetch_start"
            }
        case SINGLE_PARTICIPANT_FETCH_SUCCESS:
            return {
                ...state,
                status: "single_participant_fetch_success",
                singleParticipantData: action.payload
            }
        case SINGLE_PARTICIPANT_FETCH_FAILED:
            return {
                ...state,
                status: "single_participant_fetch_failed"
            }
        case PARTICIPANT_CREATE_START:
            return {
                ...state,
                participantCreateStatus: "participant_create_start",
            }
        case PARTICIPANT_CREATE_SUCCESS:
            return {
                ...state,
                participantCreateStatus: "participant_create_success",
                participantCreateData: action.payload
            }
        case PARTICIPANT_CREATE_FAILED:
            return {
                ...state,
                participantCreateStatus: "participant_create_failed",
            }
        case PARTICIPANT_UPDATE_START:
            return{
                ...state,
                participantUpdateStatus: "participant_update_start",
            }
        case PARTICIPANT_UPDATE_SUCCESS:
            return{
                ...state,
                participantUpdateStatus: "participant_update_success",
                participantUpdateData: action.payload
            }
        case PARTICIPANT_UPDATE_FAILED:
            return{
                ...state,
                participantUpdateStatus: "participant_update_failed",
            }
        case PARTICIPANT_UPDATE_SUCCESS_STOP:
            return{
                ...state,
                participantUpdateStatus: "participant_update_success_stop",
                participantUpdateSuccessData: {
                    participantUpdateData: action.payload
                }
            }
        case ENROLLMENT_UPDATE_START:
            return{
                ...state,
                enrollmentUpdateStatus: "enrollment_update_start",
            }
        case ENROLLMENT_UPDATE_SUCCESS:
            return{
                ...state,
                enrollmentUpdateStatus: "enrollment_update_success",
                enrollmentUpdateData: action.payload
            }
        case ENROLLMENT_UPDATE_FAILED:
            return{
                ...state,
                enrollmentUpdateStatus: "enrollment_update_failed",
            }
        case ENROLLMENT_UPDATE_SUCCESS_STOP:
            return{
                ...state,
                enrollmentUpdateStatus: "enrollment_update_success_stop",
                enrollmentUpdateSuccessData: {
                    enrollmentUpdateData: action.payload
                }
            }
        case MEDIA_UPDATE_START:
            return{
                ...state,
                mediaUpdateStatus: "media_update_start",
            }
        case MEDIA_UPDATE_SUCCESS:
            return{
                ...state,
                mediaUpdateStatus: "media_update_success",
                mediaUpdateData: action.payload
            }
        case MEDIA_UPDATE_FAILED:
            return{
                ...state,
                mediaUpdateStatus: "media_update_failed",
            }
        case MEDIA_UPDATE_SUCCESS_STOP:
            return{
                ...state,
                mediaUpdateStatus: "media_update_success_stop",
                mediaUpdateSuccessData: {
                    mediaUpdateData: action.payload
                }
            }
        case MEDICAL_UPDATE_START:
            return{
                ...state,
                medicalUpdateStatus: "medical_update_start",
            }
        case MEDICAL_UPDATE_SUCCESS:
            return{
                ...state,
                medicalUpdateStatus: "medical_update_success",
                medicalUpdateData: action.payload
            }
        case MEDICAL_UPDATE_FAILED:
            return{
                ...state,
                medicalUpdateStatus: "medical_update_failed",
            }
        case MEDICAL_UPDATE_SUCCESS_STOP:
            return{
                ...state,
                medicalUpdateStatus: "medical_update_success_stop",
                medicalUpdateSuccessData: {
                    medicalUpdateData: action.payload
                }
            }
        case KITCHEN_UPDATE_START:
            return{
                ...state,
                kitchenUpdateStatus: "kitchen_update_start",
            }
        case KITCHEN_UPDATE_SUCCESS:
            return{
                ...state,
                kitchenUpdateStatus: "kitchen_update_success",
                kitchenUpdateData: action.payload
            }
        case KITCHEN_UPDATE_FAILED:
            return{
                ...state,
                kitchenUpdateStatus: "kitchen_update_failed",
            }
        case KITCHEN_UPDATE_SUCCESS_STOP:
            return{
                ...state,
                kitchenUpdateStatus: "kitchen_update_success_stop",
                kitchenUpdateSuccessData: {
                    kitchenUpdateData: action.payload
                }
            }
        case PARTICIPANT_ARCHIVE_START:
            return{
                ...state,
                participantArchiveStatus: "participant_archive_start",
            }
        case PARTICIPANT_ARCHIVE_SUCCESS:
            return{
                ...state,
                participantArchiveStatus: "participant_archive_success",
                data: action.payload.res,
                participantArchiveSuccessData: {
                    ...state.participantArchiveSuccessData,
                    participantData: action.payload.selectedParticipant
                }
            }
        case "ARCHIVE_OPERATION":
            return {
                ...state,
                archiveOperationInfo: action.payload
            }
        case PARTICIPANT_ARCHIVE_FAILED:
            return{
                ...state,
                participantArchiveStatus: "participant_archive_failed",
            }
        case PARTICIPANT_ARCHIVE_SUCCESS_STOP:
            const { payload, archiveType, appUrlPathname, t } = action;
            const selectedParticipant = payload;
            const checkParticipant = state.allParticipantData.find((data) => data.id === payload.id);
            if(archiveType === "yes") {
                Swal.fire({
                    title: `${t('swal-messages.Success')}!`,
                    // text: `Participant archived successfully.`,
                    icon: 'success',
                })
                .then((result) => {
                    if(result.isConfirmed) {
                        window.location.assign(appUrlPathname)
                    }
                })
                selectedParticipant.is_archived = "yes";
            }else if(archiveType === "no") {
                Swal.fire({
                    title: `${t('swal-messages.Success')}!`,
                    // text: `Participant unarchived successfully.`,
                    icon: 'success',
                })
                .then((result) => {
                    if(result.isConfirmed) {
                        window.location.assign(appUrlPathname)
                    }
                })
                selectedParticipant.is_archived = "no";
            }
            return{
                ...state,
                participantArchiveStatus: "participant_archive_success_stop",
                allParticipantArchiveData: checkParticipant && checkParticipant.is_archived === selectedParticipant.is_archived ? state.allParticipantData : [...state.allParticipantData, selectedParticipant],
                participantArchiveSuccessData: {
                    ...state.participantArchiveSuccessData,
                    participantData: selectedParticipant
                }
            }
        case PARTICIPANT_DELETE_START:
            return {
                ...state,
                participantDeleteStatus: "participant_delete_start",
            };
        case PARTICIPANT_DELETE_SUCCESS:
            return {
                ...state,
                participantDeleteStatus: "participant_delete_success",
                allParticipantData: state.allParticipantData.filter(participant => participant.id !== action.payload.id),
            };
        case PARTICIPANT_DELETE_FAILED:
            return {
                ...state,
                participantDeleteStatus: "participant_delete_failed",
            };
        case PARTICIPANT_DELETE_STOP:
            return {
                ...state,
                participantDeleteStatus: "",
            };
        default:
            return {
                ...state
            }
    }
}